import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlankState from "../../../components/BlankState";
import DialogStructure, { DialogStructureProps } from "../../../components/DialogStructure";
import { Button } from "@mui/material";
import { faFaceHandPeeking } from "@fortawesome/pro-duotone-svg-icons";


export interface MaxDailyQuestionsDialogProps extends DialogStructureProps {
    onPremiumClick?: () => void;
}
export default function MaxDailyQuestionsDialog({ onPremiumClick, ...props }: MaxDailyQuestionsDialogProps) {

    return (
        <DialogStructure title="Maximo de preguntas diarias alcanzado" {...props}>
            <BlankState mt={0}
                icon={<FontAwesomeIcon icon={faFaceHandPeeking} size="4x" />}
                title="Máximo diario alcanzado"
                subtitle={[
                    "Lo sentimos, has alcanzado el máximo de 50 preguntas diarias.",
                    "Si deseas hacer preguntas sin límite, y de paso, apoyar pequeños proyectos como este:",
                    "Hazte premium para siempre por solo un único pago de 10€."
                ].join("\n\n")}
                footer={onPremiumClick ? (
                    <Button variant="contained" fullWidth
                        onClick={onPremiumClick}
                    >
                        ¿A qué esperas para hacerte PREMIUM?
                    </Button>
                ) : undefined}
            />
            <Button color="inherit" variant="outlined" sx={{ mt: 2 }} onClick={() => props.onClose?.({}, "backdropClick")} fullWidth>Prefiero continuar gratuitamente</Button>
        </DialogStructure>
    )
}