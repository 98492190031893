import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import './sweetAlert.css';

export const success = (title: string, text?: string, extraOptions?: SweetAlertOptions) => {
    return Swal.fire({
        icon: 'success', title, text,
        showCloseButton: true,
        showConfirmButton: false,
        ...extraOptions
    });
}

export const info = (title: string, extraOptions?: SweetAlertOptions) => {
    return Swal.fire({
        icon: 'info', title,
        showCloseButton: true,
        ...extraOptions
    });
}

export const error = (title: string, extraOptions?: SweetAlertOptions) => {
    return Swal.fire({
        icon: 'error', title,
        showCloseButton: true,
        ...extraOptions
    });
}

export const question = (title: string, extraOptions?: SweetAlertOptions): Promise<SweetAlertResult<any>> => {
    return Swal.fire({
        icon: 'question',
        title,
        showCloseButton: true,
        showCancelButton: true,
        ...extraOptions
    });
}

export const criticalError = async () => {
    const res = await Swal.fire({
        icon: 'error',
        title: "Error inesperado",
        text: "Ha surgido un problema en la carga de datos.",
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonText: "Recargar página",
        cancelButtonText: "Salir",
        allowOutsideClick: false,
        footer: '¿El error continúa? <a style="margin-left: 5px" href="mailto:nitrogenaddicts@gmail.com">Notificar error.</a>'
    });
    if (res.isConfirmed) window.location.reload();
    else window.location.href = "/";
}