import { Button, Divider, FormControlLabel, Grid, InputAdornment, Link, Switch, TextField, TextFieldProps, Typography } from "@mui/material";
import { RegisterRequest, register } from "../authAPI";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { faEnvelope, faKey, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { AuthResponse } from "../interfaces/LoginResponse";
import { routes } from "../../../routes";
import { Link as RouterLink } from "react-router-dom"

export interface RegisterFormProps {
    value?: Partial<RegisterRequest>,
    onSubmit?: (data: AuthResponse) => void,
    onLoginClick?: () => void
}
export default function RegisterForm({ value, onSubmit = () => { }, onLoginClick }: RegisterFormProps) {
    const [form, setForm] = useState<Partial<RegisterRequest>>({});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setForm(value || {}), [value]);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            if (form.password !== form.repeatPassword) return enqueueSnackbar("Las contraseñas han de ser iguales", { variant: "error" });
            const res = await register(form as RegisterRequest);
            enqueueSnackbar("Usuario registrado correctamente", { variant: "success" });
            onSubmit(res.data);
        } catch (err: any) {
            enqueueSnackbar(err.response.data.message || "Error inesperado", { variant: "error" });
        }
    }

    const generateTextField = (label: string, attr: keyof RegisterRequest, icon: FontAwesomeIconProps["icon"], textFieldProps: TextFieldProps = {}) => (
        <TextField
            {...textFieldProps}
            value={form[attr] || ""}
            label={label} variant="outlined" fullWidth
            onChange={e => setForm({ ...form, [attr]: e.target.value })}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <FontAwesomeIcon icon={icon} />
                    </InputAdornment>
                ),
            }}
            required
            inputProps={{ autoComplete: 'off', "aria-autocomplete": "none", ...textFieldProps.inputProps }}
        />
    );

    return (
        <Fragment>

            {/* REGISTER FORM */}
            <Grid container spacing={3} component="form" mb={4} onSubmit={handleSubmit}>
                <Grid item xs={12}>
                    <Typography variant="h4" color="text.secondary">Regístrate</Typography>
                </Grid>
                <Grid item xs={12}>
                    {generateTextField("Nombre", "name", faUser)}
                </Grid>
                <Grid item xs={12}>
                    {generateTextField("Email", "email", faEnvelope, { type: "email" })}
                </Grid>
                <Grid item xs={12}>
                    {generateTextField("Contraseña", "password", faKey, { type: "password", inputProps: { minLength: 8 } })}
                </Grid>
                <Grid item xs={12}>
                    {generateTextField("Repetir contraseña", "repeatPassword", faKey, { type: "password", inputProps: { minLength: 8 } })}
                </Grid>
                <Grid item xs={12} textAlign="left">
                    <FormControlLabel control={<Switch sx={{ mx: 1 }} required />} label={(
                        <Typography variant="body2">He leído y acepto la <Link component={RouterLink} color="inherit" to={routes.privacyPolicyPage.path} target="_blank">Política de Privacidad</Link>.</Typography>
                    )} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth type="submit">Crear cuenta</Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Divider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" display="inline-block" sx={{ mr: 1 }}>¿Ya tienes una cuenta?</Typography>
                    <Link variant="body2" underline="none" sx={{ cursor: "pointer" }} onClick={onLoginClick}>
                        Inicia sesión aquí
                    </Link>
                </Grid>
            </Grid>

        </Fragment>
    );
}