import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateQuizzForm } from "./CreateQuizzPage";

interface QuizzState { createQuizzForm: CreateQuizzForm };
export const initialState: QuizzState = { createQuizzForm: { size: 10, type: "RANDOM" } };

export const quizzSlice = createSlice({
    name: 'quizz',
    initialState,
    reducers: {
        setCreateQuizzForm: (state, action: PayloadAction<CreateQuizzForm>) => {
            state.createQuizzForm = action.payload
        }
    }
});

export const { setCreateQuizzForm } = quizzSlice.actions;

export default quizzSlice.reducer;