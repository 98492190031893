import { ReactNode, createContext, useContext, useState } from 'react';
import RequirePremiumDialog, { RequirePremiumDialogProps } from './dialogs/RequirePremiumDialog';
import { useAppSelector } from '../../app/hooks';
import { DialogStructureProps } from '../../components/DialogStructure';
import { useAuthDialog } from '../auth/dialogs/AuthDialogProvider';
import MaxDailyQuestionsDialog from './dialogs/MaxDailyQuestionsDialog';
import PremiumFunctionalityDialog from './dialogs/PremiumFunctionalityDialog';

export interface PremiumDialogContextType {
    openPremiumInfoDialog: (props?: Partial<RequirePremiumDialogProps>) => void;
    openMaxDailyQuestionsDialog: (props?: Partial<DialogStructureProps>) => void;
    openPremiumFunctionalityDialog: (props?: Partial<DialogStructureProps>) => void;
}
const PremiumDialogContext = createContext<PremiumDialogContextType | undefined>(undefined);

export default function PremiumDialogProvider({ children }: { children: ReactNode }) {
    const user = useAppSelector(state => state.auth.user);
    const { openAuthDialog } = useAuthDialog();
    const [premiuminfoDialogProps, setPremiumInfoDialogProps] = useState<RequirePremiumDialogProps>();
    const [maxDailyQuestionsDialog, setMaxDailyQuestionsDialog] = useState<DialogStructureProps>();
    const [premiumFunctionalityDialog, setPremiumFunctionalityDialog] = useState<DialogStructureProps>();

    const openPremiumInfoDialog = (props?: Partial<RequirePremiumDialogProps>) => {
        user ? 
        setPremiumInfoDialogProps({ ...props, open: true })
        :
        openAuthDialog();
    }
    const openMaxDailyQuestionsDialog = (props?: Partial<DialogStructureProps>) => setMaxDailyQuestionsDialog({ ...props, open: true });
    const openPremiumFunctionalityDialog = (props?: Partial<DialogStructureProps>) => setPremiumFunctionalityDialog({ ...props, open: true });

    return (
        <PremiumDialogContext.Provider value={{ openPremiumInfoDialog, openMaxDailyQuestionsDialog, openPremiumFunctionalityDialog }}>
            {children}
            <RequirePremiumDialog
                onClose={() => setPremiumInfoDialogProps({ open: false })}
                {...premiuminfoDialogProps}
            />
            <MaxDailyQuestionsDialog {...maxDailyQuestionsDialog}
                onClose={() => setMaxDailyQuestionsDialog({ open: false })}
                onPremiumClick={() => {
                    if (user) setPremiumInfoDialogProps({ open: true });
                    else openAuthDialog();
                    setMaxDailyQuestionsDialog({ open: false });
                }}
            />
            <PremiumFunctionalityDialog {...premiumFunctionalityDialog}
                onClose={() => setPremiumFunctionalityDialog({ open: false })}
                onPremiumClick={() => {
                    if (user) setPremiumInfoDialogProps({ open: true });
                    else openAuthDialog();
                    setPremiumFunctionalityDialog({ open: false });
                }}
            />
        </PremiumDialogContext.Provider>
    );
};

export const usePremiumDialog = (): PremiumDialogContextType => {
    const context = useContext(PremiumDialogContext);
    if (context === undefined) throw new Error('usePremiumDialog debe ser usado dentro de un PremiumDialogProvider');
    return context;
};