import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container, { ContainerProps } from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { faArrowRightToBracket, faBallotCheck, faBars, faCircleInfo, faFileCircleXmark, faHouse, faMessagesQuestion, faRightFromBracket, faShareNodes, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { routes } from '../../routes';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout } from '../auth/authSlice';
import { useAuthDialog } from '../auth/dialogs/AuthDialogProvider';
import CategorySelect from '../category/CategorySelect';

interface HeaderProps {
    containerProps?: ContainerProps
}
export function Header({ containerProps }: HeaderProps) {
    const dispatch = useAppDispatch();
    const [anchorElNav, setAnchorElNav] = React.useState<HTMLElement | null>(null);
    const user = useAppSelector(state => state.auth.user);
    const { openAuthDialog } = useAuthDialog();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);
    const largeDevice = useMediaQuery('(min-width:300px)');

    return (
        <AppBar position="static" sx={{ backgroundColor: "grey.900" }} elevation={0}>
            <Container {...containerProps}>
                <Toolbar disableGutters>
                    <Box display="flex" alignItems="center" sx={{ 'flexGrow': 1 }}>
                        {largeDevice && (
                            <Typography variant="body2" component="h1" fontFamily="Ethnocentric" mr={1}>
                                SCS -
                            </Typography>
                        )}
                        <CategorySelect maxWidth="55vw" />
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex' }}>
                        <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElNav}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            slotProps={{ paper: { sx: { backgroundColor: "grey.800", color: "white" } } }}
                            MenuListProps={{ disablePadding: true }}
                        >
                            <MenuItem onClick={() => handleCloseNavMenu()} component={RouterLink} to={routes.homePage.path} divider>
                                <ListItemIcon>
                                    <FontAwesomeIcon color="white" icon={faHouse} />
                                </ListItemIcon>
                                <ListItemText>Inicio</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => handleCloseNavMenu()} component={RouterLink} to={routes.createQuizzPage.path} divider>
                                <ListItemIcon>
                                    <FontAwesomeIcon color="white" icon={faBallotCheck} />
                                </ListItemIcon>
                                <ListItemText>Crear cuestionario</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => handleCloseNavMenu()} component={RouterLink} to={routes.unconsolidatedUserResponsesPage.path} divider>
                                <ListItemIcon>
                                    <FontAwesomeIcon color="white" icon={faFileCircleXmark} />
                                </ListItemIcon>
                                <ListItemText>Preguntas falladas / por consolidar</ListItemText>
                            </MenuItem>
                            {user && (
                                <MenuItem onClick={() => handleCloseNavMenu()} component={RouterLink} to={routes.profilePage.path} divider>
                                    <ListItemIcon>
                                        <FontAwesomeIcon color="white" icon={faUser} />
                                    </ListItemIcon>
                                    <ListItemText>Perfil</ListItemText>
                                </MenuItem>
                            )}
                            {!user && (
                                <MenuItem onClick={() => { handleCloseNavMenu(); openAuthDialog(); }} divider>
                                    <ListItemIcon>
                                        <FontAwesomeIcon color="white" icon={faArrowRightToBracket} />
                                    </ListItemIcon>
                                    <ListItemText>Iniciar sesión</ListItemText>
                                </MenuItem>
                            )}
                            <MenuItem onClick={() => handleCloseNavMenu()} component={RouterLink} to="mailto:scspreguntas@gmail.com" divider>
                                <ListItemIcon>
                                    <FontAwesomeIcon color="white" icon={faMessagesQuestion} />
                                </ListItemIcon>
                                <ListItemText>Contactar</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => handleCloseNavMenu()} component={RouterLink} to={routes.aboutUsPage.path} divider>
                                <ListItemIcon>
                                    <FontAwesomeIcon color="white" icon={faCircleInfo} />
                                </ListItemIcon>
                                <ListItemText>Sobre nosotros</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleShareApp();
                                handleCloseNavMenu();
                            }} divider>
                                <ListItemIcon>
                                    <FontAwesomeIcon color="white" icon={faShareNodes} size='lg' />
                                </ListItemIcon>
                                <ListItemText>Compartir APP</ListItemText>
                            </MenuItem>
                            {user && (
                                <MenuItem onClick={() => { handleCloseNavMenu(); dispatch(logout()); }} divider>
                                    <ListItemIcon>
                                        <FontAwesomeIcon color="white" icon={faRightFromBracket} />
                                    </ListItemIcon>
                                    <ListItemText>Cerrar sesión</ListItemText>
                                </MenuItem>
                            )}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
}

const shareData = {
    title: "SCS - Preguntas",
    text: [
        "Con SCS - Preguntas podrás practicar las preguntas del Servicio Canario de Salud cuando y donde quieras.",
        "¡Instalable en Android, iOS y Windows!",
        "¿A qué esperas para probarla?",
    ].join("\n\n"),
    url: "https://scspreguntas.com/",
};

export const handleShareApp = async () => {
    try {
        await navigator.share(shareData);
    } catch (err) {
        window.open(`https://api.whatsapp.com/send?text=${encodeURI([shareData.text, shareData.url].join("\n\n"))}`, "_blank");
    }
}