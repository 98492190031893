import { Menu, MenuProps } from "@mui/material";
import { ReactNode } from "react";

export interface SimpleMenuProps extends Partial<MenuProps> {
    children: ReactNode | ReactNode[]
}
export default function SimpleMenu({ children, ...menuProps }: SimpleMenuProps) {

    const open = Boolean(menuProps.anchorEl);

    const { MenuListProps, ...restMenuProps } = menuProps;
    return (
        <Menu {...{
            MenuListProps: { sx: { py: 0 }, ...MenuListProps },
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
            ...restMenuProps, open
        }}>
            {children}
        </Menu>
    );
}