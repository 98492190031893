import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlankState, { BlankStateProps } from "../../../components/BlankState";
import { faFaceGrinBeamSweat } from "@fortawesome/pro-duotone-svg-icons";
import { Button } from "@mui/material";

export interface PremiumFunctionalityStateProps extends BlankStateProps {
    onPremiumClick?: () => void
}
export default function PremiumFunctionalityState({ onPremiumClick, ...props }: PremiumFunctionalityStateProps) {
    return (
        <BlankState {...props}
            icon={<FontAwesomeIcon icon={faFaceGrinBeamSweat} size="4x" />}
            title="Lo sentimos"
            subtitle={[
                "Dicha funcionalidad es exclusiva para cuentas PREMIUM.",
                "Si deseas usar todas las funcionalidades disponibles, hacer preguntas sin límites, y de paso, apoyar pequeños proyectos como este.",
                "Hazte premium para siempre por solo un único pago de 10€."
            ].join("\n\n")}
            footer={onPremiumClick ? (
                <Button variant="contained" onClick={onPremiumClick} fullWidth>
                    ¿A qué esperas para hacerte PREMIUM?
                </Button>
            ) : undefined}
        />
    )
}