import DialogStructure, { DialogStructureProps } from "../../../components/DialogStructure"
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { getCheckoutUrl } from "../stripeApi"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleExclamation, faCircleXmark } from "@fortawesome/pro-duotone-svg-icons";

export interface RequirePremiumDialogProps extends DialogStructureProps { }
export default function RequirePremiumDialog({ ...dialogProps }: RequirePremiumDialogProps) {
    const [url, setUrl] = useState<string>();
    useEffect(() => { if (dialogProps.open) getCheckoutUrl().then(res => setUrl(res.data.url)); }, [dialogProps.open]);
    if (!url) return null;

    const plans = [
        {
            paperProps: { elevation: 1, sx: { backgroundColor: "grey.200", p: 3, border: "1px solid #999999" } },
            title1: "Premium",
            title2: "10€",
            subtitle: "para siempre",
            features: [
                { label: "Preguntas ilimitadas", type: "UNLIMITED" },
                { label: "Preguntas aleatorias", type: "UNLIMITED" },
                { label: "Preguntas por más falladas", type: "UNLIMITED" },
                { label: "Preguntas por más antiguas", type: "UNLIMITED" },
                { label: "Mezclar respuestas", type: "UNLIMITED" },
                { label: "Visualizar preguntas más falladas", type: "UNLIMITED" },
            ],
            footer: (
                <Button variant="contained" color="primary" sx={{ mt: 2 }} href={url} target="_blank" fullWidth>
                    PASAR A PREMIUM
                </Button>
            )
        },
        {
            paperProps: { elevation: 1, sx: { p: 3, border: "1px solid #999999" } },
            title1: "Normal",
            title2: "-",
            subtitle: "gratis",
            features: [
                { label: "50 preguntas/día", type: "LIMITED" },
                { label: "Preguntas aleatorias", type: "UNLIMITED" },
                { label: "Preguntas por más falladas", type: "NONE" },
                { label: "Preguntas por más antiguas", type: "NONE" },
                { label: "Mezclar respuestas", type: "NONE" },
                { label: "Visualizar preguntas más falladas", type: "NONE" },
            ],
            footer: (
                <Button variant="outlined" color="inherit" sx={{ mt: 2 }} onClick={() => dialogProps.onClose?.({}, "backdropClick")} fullWidth>
                    CONTINUAR GRATIS
                </Button>
            )
        },
    ]

    return (
        <DialogStructure title="Hazte Premium" {...dialogProps}>
            <Grid container spacing={2}>
                {plans.map((p, index) => (
                    <Grid key={index} item xs={12} sm={6}>
                        <Paper {...p.paperProps}>
                            <Grid container textAlign="center">
                                <Grid item xs={12}>
                                    <Typography variant="button" fontSize={26}>{p.title1}</Typography>
                                    <Typography variant="h5">{p.title2}</Typography>
                                    <Typography variant="subtitle1" color="text.secondary">{p.subtitle}</Typography>
                                </Grid>
                                <Grid item xs={12} my={2}>
                                    <Divider variant="middle" />
                                </Grid>
                                {p.features.map((e, index) => (
                                    <Grid item key={index} xs={12} display="flex" justifyContent="center" alignItems="top" mb={1}>
                                        {e.type === "UNLIMITED" && <Box color="success.dark"><FontAwesomeIcon icon={faCircleCheck} /></Box>}
                                        {e.type === "LIMITED" && <Box color="warning.dark"><FontAwesomeIcon icon={faCircleExclamation} /></Box>}
                                        {e.type === "NONE" && <Box color="error.dark"><FontAwesomeIcon icon={faCircleXmark} /></Box>}
                                        <Typography ml={1} textTransform="unset" fontSize={16} variant="overline" lineHeight="unset"
                                            color={e.type === "LIMITED" ? "warning.dark" : (e.type === "NONE" ? "text.secondary" : undefined)}
                                        >{e.label}</Typography>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    {p.footer}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </DialogStructure>
    )
}