import { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { DialogStructureProps } from '../../../components/DialogStructure';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import AuthDialog from './AuthDialog';
import { getItem, removeItem } from '../../../services/localStorage';
import axios from 'axios';
import { getUser } from '../authSlice';

export interface AuthDialogContextType {
    openAuthDialog: (props?: Partial<DialogStructureProps>) => void;
}
const AuthDialogContext = createContext<AuthDialogContextType | undefined>(undefined);

export default function AuthDialogProvider({ children }: { children: ReactNode }) {
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.auth.user);
    const [authDialogProps, setAuthDialogProps] = useState<DialogStructureProps>();
    const openAuthDialog = (props?: Partial<DialogStructureProps>) => setAuthDialogProps({ ...props, open: true });

    useEffect(() => {
        const token = getItem("JWT");
        if (token && !user) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            dispatch(getUser()).unwrap().catch(e => {
                console.error(e);
                removeItem("JWT");
            });
        }
    }, [])

    return (
        <AuthDialogContext.Provider value={{ openAuthDialog }}>
            {children}

            <AuthDialog
                onClose={() => setAuthDialogProps({ open: false })}
                {...authDialogProps}
            />
        </AuthDialogContext.Provider>
    );
};

export const useAuthDialog = (): AuthDialogContextType => {
    const context = useContext(AuthDialogContext);
    if (context === undefined) throw new Error('useAuthDialog debe ser usado dentro de un AuthDialogProvider');
    return context;
};