import { Button, Grid, Typography } from "@mui/material";
import DialogStructure, { DialogStructureProps } from "../../components/DialogStructure";

import shareButton from "./assets/shareButton.svg";
import ios1 from "./assets/ios1.jpeg";
import ios2 from "./assets/ios2.jpeg";

export default function InstallPWAInIOSDialog(props: DialogStructureProps) {

    return (
        <DialogStructure title="Instalar APP en IOS" {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography mb={1}>1. Pulse el botón compartir <img src={shareButton} /> en la barra de dirección:</Typography>
                    <img src={ios1} width="100%" />
                </Grid>
                <Grid item xs={12}>
                    <Typography mb={1}>2. Pulse "Añadir a pantalla de inicio":</Typography>
                    <img src={ios2} width="100%" />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Button variant="contained" onClick={() => props.onClose?.({}, "backdropClick")} fullWidth>CERRAR</Button>
                </Grid>
            </Grid>
        </DialogStructure>
    )
}