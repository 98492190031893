import { Box, BoxProps, ListItemText, ListSubheader, MenuItem, Typography } from "@mui/material";
import SimpleMenu from "../../components/SimpleMenu/SimpleMenu";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { categories, definitiveCategories, setDefaultCategoryAndUpdateUser } from "./categorySlice";
import { Category } from "../../interfaces/Category";


export default function CategorySelect(props: Partial<BoxProps>) {
    const dispatch = useAppDispatch();
    const category = useAppSelector(state => state.category.defaultCategory);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const selectCategory = (category: Category) => {
        dispatch(setDefaultCategoryAndUpdateUser(category));
        handleClose();
    }

    return (
        <Box {...props}>
            <Box display="flex" onClick={handleClick} minWidth={0} sx={{ cursor: "pointer" }}>
                <Typography variant="body2" fontFamily="Ethnocentric" mr={1} noWrap>
                    {category?.label || "Seleccione categoría"}
                </Typography>
                <FontAwesomeIcon icon={faChevronDown} />
            </Box>
            <SimpleMenu sx={{ mt: 1 }} anchorEl={anchorEl} onClose={handleClose} slotProps={{ paper: { sx: { backgroundColor: "grey.800", color: "white" } } }}>
                <ListSubheader sx={{ fontWeight: "bold", backgroundColor: "grey.300" }}>Preguntas Definitivas</ListSubheader>
                {definitiveCategories.map((e) => (
                    <MenuItem key={e.value} onClick={() => selectCategory(e)} sx={{ borderColor: "grey.600" }} divider>
                        <Typography variant="body2">{e.label}</Typography >
                    </MenuItem>
                ))}
                <ListSubheader sx={{ fontWeight: "bold", backgroundColor: "grey.300" }}>Preguntas Provisionales</ListSubheader>
                {categories.map((e) => (
                    <MenuItem key={e.value} onClick={() => selectCategory(e)} sx={{ borderColor: "grey.600" }} divider>
                        <Typography variant="body2">{e.label}</Typography >
                    </MenuItem>
                ))}
            </SimpleMenu>
        </Box>
    )
}