import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../interfaces/User";
import * as api from "./authAPI";
import { AuthResponse } from "./interfaces/LoginResponse";
import { removeItem, setItem } from "../../services/localStorage";
import axios from "axios";
import { RootState } from "../../app/store";
import { setUserCategory } from "../category/categorySlice";

interface AuthState { user?: User };
export const initialState: AuthState = { user: undefined };

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        logout: (state) => {
            removeItem("JWT");
            delete axios.defaults.headers.common["Authorization"];
            state.user = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(getUser.fulfilled, (state, action) => { state.user = action.payload; });
        builder.addCase(updateUser.fulfilled, (state, action) => { state.user = action.payload; });
        builder.addCase(authenticateUser.fulfilled, (state, action) => { state.user = action.payload; });
    }

});

export const { setUser, logout } = authSlice.actions;

export default authSlice.reducer;

// Thunk functions
export const getUser = createAsyncThunk('auth/getUser', async (undefined, { dispatch }) => {
    const user = (await api.getUser()).data;
    if (user.defaultCategory) dispatch(setUserCategory(user.defaultCategory));
    return user;
});

export const updateUser = createAsyncThunk<User, Partial<User>, { state: RootState }>('auth/updateUser', async (value: Partial<User>, { dispatch }) => {
    const user = (await api.updateUser(value)).data;
    if (user.defaultCategory) dispatch(setUserCategory(user.defaultCategory));
    return user;
});

export const authenticateUser = createAsyncThunk<User, AuthResponse, { state: RootState }>('auth/authenticateUser', async (value, { dispatch }) => {
    const { user, token } = value;
    if (user.defaultCategory) dispatch(setUserCategory(user.defaultCategory));
    setItem("JWT", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return user;
});