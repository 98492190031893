import { Navigate, Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import { Button, Container, CssBaseline, IconButton, ThemeProvider, createTheme } from "@mui/material";
import { Header } from "./features/header/Header";
import axios from "axios";
import { routes } from "./routes";
import { SnackbarProvider, useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFaceParty } from "@fortawesome/pro-duotone-svg-icons";
import AuthDialogProvider from "./features/auth/dialogs/AuthDialogProvider";
import InstallPwaBanner from "./features/installPwaBanner/InstallPwaBanner";
import PremiumDialogProvider from "./features/premium/PremiumDialogProvider";
import MaintenancePage from "./features/maintenance/MaintenancePage";
import AxiosLoading from "./features/loading/AxiosLoading";
import { useEffect, useState } from "react";
import DialogStructure from "./components/DialogStructure";
import BlankState from "./components/BlankState";

const darkTheme = createTheme({
  palette: {
    background: {
      default: "#fbfbfb"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { textTransform: "unset" }
      }
    }
  }
});

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openThanks, setOpenThanks] = useState(false);

  useEffect(() => {
    const premium = searchParams.get("premium");
    if (premium) {
      searchParams.delete("premium");
      setSearchParams(searchParams);
      setOpenThanks(true);
    }
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ flexWrap: "nowrap", whiteSpace: "pre-line" }} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />} preventDuplicate>
        <AuthDialogProvider>
          <PremiumDialogProvider>
            <CssBaseline />
            <Routes>
              {import.meta.env.VITE_MAINTENANCE === "1" && <Route path="*" element={<MaintenancePage />} />}
              {(!import.meta.env.VITE_MAINTENANCE || import.meta.env.VITE_MAINTENANCE === "0")  && (
                <Route element={<Layout />}>
                  {Object.values(routes).map((e, index) => (
                    <Route key={index} path={e.path} element={e.element} />
                  ))}
                  <Route path="*" element={<Navigate to={routes.createQuizzPage.path} />} />
                </Route>
              )}
            </Routes>
            <AxiosLoading />
            <DialogStructure title="Ya eres premium" open={openThanks} onClose={() => setOpenThanks(false)}>
              <BlankState mt={0}
                icon={<FontAwesomeIcon icon={faFaceParty} size="4x" />}
                title="Enhorabuena, ya eres PREMIUM"
                subtitle={[
                  "Ahora podrás realizar todas las preguntas que quieras sin ningún tipo de limitación.",
                  "Además, has contribuido a apoyar proyectos tan modestos como este."
                ].join("\n\n")}
                footer={<Button variant="contained" onClick={() => setOpenThanks(false)} fullWidth>Cerrar</Button>}
              />
            </DialogStructure>
          </PremiumDialogProvider>
        </AuthDialogProvider>
      </SnackbarProvider>
    </ThemeProvider >
  )
}

const Layout = () => {
  return (
    <>
      <Header containerProps={{ maxWidth: "md" }} />
      <InstallPwaBanner />
      <Container maxWidth="sm" sx={{ py: 2 }}>
        <Outlet />
      </Container>
    </>
  )
};

const SnackbarCloseButton = ({ snackbarKey }: any) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <FontAwesomeIcon icon={faClose} />
    </IconButton>
  );
}