import { faClose } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";

export interface DialogStructureProps extends Partial<Omit<DialogProps, "onSubmit">> {
    title?: string,
}
export default function DialogStructure({
    title = "", open = false, onClose, children, ...otherProps
}: DialogStructureProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog fullScreen={fullScreen} open={open} onClose={onClose} {...otherProps}>
            <DialogTitle sx={{ color: "text.primary" }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    {title || <div></div>}
                    <IconButton color="inherit" onClick={() => onClose?.({}, "backdropClick")} sx={{ ml: 1 }}>
                        <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
}