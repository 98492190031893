import { AppBar, Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { isInStandaloneMode, isIos } from "./helpers";
import InstallPWAInIOSDialog from "./InstallPWAInIOSDialog";

interface PromptEvent extends Event { prompt: () => void; }
export default function InstallPwaBanner() {
    const [installPrompt, setInstallPrompt] = useState<null | PromptEvent>(null);
    const [iosDialog, setIosDialog] = useState(false);
    
    useEffect(() => {
        const beforeinstallprompt = (event: Event) => {
            event.preventDefault();
            if ("prompt" in event && typeof event.prompt === "function") setInstallPrompt(event as PromptEvent);
        }

        const appinstalled = () => setInstallPrompt(null);

        window.addEventListener("beforeinstallprompt", event => beforeinstallprompt(event));
        window.addEventListener("appinstalled", _ => appinstalled());
        return () => { 
            window.removeEventListener("beforeinstallprompt", beforeinstallprompt); 
            window.removeEventListener("appinstalled", appinstalled); 
        }
    }, []);

    const handleInstall = () => {
        if (installPrompt) installPrompt.prompt();
        else setIosDialog(true);
    }

    const showBanner = installPrompt !== null || (!isInStandaloneMode() && isIos());
    if (!showBanner) return null;
    return (
        <AppBar position="static" sx={{ backgroundColor: "grey.600" }} elevation={0}>
            <Container maxWidth="md">
                <Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
                    <Typography variant="caption" mr={2}>Si lo deseas, también puedes instalar nuestra app</Typography>
                    <Button variant="contained" color="primary" size="small" sx={{ minWidth: "fit-content" }} onClick={handleInstall}>INSTALAR</Button>
                </Box>
            </Container>
            <InstallPWAInIOSDialog open={iosDialog} onClose={() => setIosDialog(false)} />
        </AppBar>
    )
}