import { useState } from "react";
import DialogStructure, { DialogStructureProps } from "../../../components/DialogStructure";
import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import { AuthResponse } from "../interfaces/LoginResponse";
import { useAppDispatch } from "../../../app/hooks";
import { authenticateUser } from "../authSlice";


export default function AuthDialog(props: DialogStructureProps) {
    const [display, setDisplay] = useState<"LOGIN" | "REGISTER">("LOGIN");
    const dispatch = useAppDispatch();

    const handleSignIn = (data: AuthResponse) => {
        dispatch(authenticateUser(data));
        props.onClose?.({}, "backdropClick");
    }

    const id = "authDialog";
    return (
        <DialogStructure title="Identifícate" {...props} id={id}>
            {display === "LOGIN" ?
                <LoginForm onRegisterClick={() => setDisplay("REGISTER")} onSubmit={handleSignIn} target={id} />
                :
                <RegisterForm onLoginClick={() => setDisplay("LOGIN")} onSubmit={handleSignIn} />
            }
        </DialogStructure>
    )
}