import { faBoxOpen, faSlash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Box, Grid, GridProps, Typography, useTheme } from "@mui/material";
import { CSSProperties, ReactNode, cloneElement } from "react";

export interface BlankStateProps extends Omit<Partial<GridProps>, "title" | "direction"> {
    icon?: ReactNode;
    title?: ReactNode;
    subtitle?: ReactNode;
    footer?: ReactNode;
    direction?: "responsive" | CSSProperties["flexDirection"]
}
export default function BlankState({
    icon = <SlashIcon icon={<FontAwesomeIcon icon={faBoxOpen} />} />,
    title = "Sin registros",
    subtitle = "Aún no has creado ningún registro.\n¿A qué esperas para crearlo?",
    footer,
    direction = "column",
    ...gridProps
}: BlankStateProps) {

    const calculateMR = () => {
        if (direction === "row") return 4;
        else if (direction === "responsive") return { xs: 0, sm: 4 };
        else return 0;
    }

    const calculateMB = () => {
        if (direction === "column") return 2;
        else if (direction === "responsive") return { xs: 2, sm: 0 };
        else return 0;
    }

    return (
        <Grid container {...{ spacing: 3, textAlign: "center", ...gridProps }}>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" flexDirection={(direction === "responsive" ? { xs: "column", sm: "row" } : direction)}>
                <Box mr={calculateMR()} mb={calculateMB()}>
                    {icon}
                </Box>
                <Box>
                    {typeof title === "string" ? (
                        <Typography variant="h4">{title}</Typography>
                    ) : (title)}
                    {subtitle && (
                        typeof subtitle === "string" ? (
                            <Typography variant="body2" color="text.secondary" whiteSpace="break-spaces" mt={2}>{subtitle}</Typography>
                        ) : (subtitle)
                    )}
                </Box>
            </Grid>
            {
                footer && <Grid item xs={12} mt={1}>
                    {footer}
                </Grid>
            }
        </Grid >
    );
}

export function SlashIcon({ icon, size = "2x" }: { icon: JSX.Element, size?: FontAwesomeIconProps["size"] }) {
    const theme = useTheme();
    return (
        <span className={`fa-stack fa-${size}`}>
            {cloneElement(icon, { className: "fa-stack-2x" })}
            <FontAwesomeIcon icon={faSlash} color={theme.palette.text.secondary} className="fa-stack-2x" />
        </span>
    );
}