import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import authSlice from "../features/auth/authSlice"
import categorySlice from "../features/category/categorySlice"
import quizzSlice from "../features/quizzes/quizzSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    category: categorySlice,
    quizz: quizzSlice
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
