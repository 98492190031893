import React from 'react';
import Loading from './features/loading/Loading';

const addReactSuspense = (c: JSX.Element) => (<React.Suspense fallback={<Loading open />}>{c}</React.Suspense>);

//Lazy loading imports
const HomePage = React.lazy(() => import("./features/home/HomePage"));
const CreateQuizzPage = React.lazy(() => import("./features/quizzes/CreateQuizzPage"));
const QuizzPage = React.lazy(() => import("./features/quizzes/QuizzPage"));
const ProfilePage = React.lazy(() => import("./features/profile/ProfilePage"));
const UnconsolidatedUserResponsesPage = React.lazy(() => import("./features/unconsolidatedUserResponses/UnconsolidatedUserResponsesPage"));
const PrivacyPolicyPage = React.lazy(() => import("./features/privacyPolicy/PrivacyPoliciPage"));
const ResetPasswordPage = React.lazy(() => import('./features/auth/ResetPasswordPage'));
const AboutUsPage = React.lazy(() => import('./features/aboutUs/AboutUsPage'));

//Routes
export const routes = {
  homePage: { path: "/", element: addReactSuspense(<HomePage />) },
  createQuizzPage: { path: "/crear-cuestionario", element: addReactSuspense(<CreateQuizzPage />) },
  quizzPage: { path: "/cuestionario", element: addReactSuspense(<QuizzPage />) },
  unconsolidatedUserResponsesPage: { path: "/preguntas-falladas", element: addReactSuspense(<UnconsolidatedUserResponsesPage />) },
  profilePage: { path: "/perfil", element: addReactSuspense(<ProfilePage />) },
  privacyPolicyPage: { path: "/politica-de-privacidad", element: addReactSuspense(<PrivacyPolicyPage />) },
  resetPasswordPage: { path: "/cambiar-contraseña", element: addReactSuspense(<ResetPasswordPage />) },
  aboutUsPage: { path: "/sobre-nosotros", element: addReactSuspense(<AboutUsPage />) },
};