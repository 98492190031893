import axios from "axios";
import { User } from "../../interfaces/User";
import { AuthResponse } from "./interfaces/LoginResponse";

export interface RegisterRequest { name: string, email: string, password: string, repeatPassword: string }
export const register = async (registerRequest: RegisterRequest) => {
    return await axios.post<AuthResponse>('/auth/register', registerRequest);
}

export interface LoginRequest { email: string, password: string }
export const login = async (loginRequest: LoginRequest) => {
    return await axios.post<AuthResponse>('/auth/login', loginRequest);
}

export interface ResetPasswordRequest { email: string, password: string, repeatPassword: string, token: string }
export const resetPassword = async (loginRequest: ResetPasswordRequest) => {
    return await axios.post('/auth/reset-password', loginRequest);
}
export const getResetPasswordInfo = async (token: string) => {
    return await axios.get<User>(`/auth/reset-password/${token}`);
}

export const sendResetPasswordEmail = async (email: string) => {
    return await axios.get(`/auth/send-reset-password-email/${email}`);
}

export const sendUserValidationEmail = async (email: string) => {
    return await axios.get(`/auth/send-user-validation-email/${email}`);
}

export const getUser = async () => {
    return await axios.get<User>('/me');
}

export const updateUser = async (user: Partial<User>) => {
    return await axios.put<User>('/me', user);
}