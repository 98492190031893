import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import App from "./App"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./styles/fonts/ethnocentric/ethnocentric.css";

import { BrowserRouter } from "react-router-dom";

if (["scs-preguntas.web.app", "scs-preguntas.firebaseapp.com"].some(e => e === location.host)) {
  window.location.replace("https://scspreguntas.com/");
}

const firebaseConfig = {
  apiKey: "AIzaSyCU7oAzFcEfRGx-Mit2K5A66EO0S-vI39Y",
  authDomain: "scs-preguntas.firebaseapp.com",
  projectId: "scs-preguntas",
  storageBucket: "scs-preguntas.appspot.com",
  messagingSenderId: "741141573118",
  appId: "1:741141573118:web:f0602f679c645d2a2324af",
  measurementId: "G-CMM2N6SDKZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)
