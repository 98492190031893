

export type LocalStorageKey = "JWT";

export const setItem = (key: LocalStorageKey, value: string | object) => localStorage.setItem(key, JSON.stringify(value));
export const getItem = (key: LocalStorageKey) => {
    const val = localStorage.getItem(key);
    try { return JSON.parse(val || ""); }
    catch (_) { return val; }
};
export const removeItem = (key: LocalStorageKey) => localStorage.removeItem(key);