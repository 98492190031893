import { Button, Divider, Grid, InputAdornment, Link, TextField, TextFieldProps, Typography } from "@mui/material";
import { LoginRequest, login, sendResetPasswordEmail } from "../authAPI";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { faEnvelope, faKey } from "@fortawesome/pro-duotone-svg-icons";
import { AuthResponse } from "../interfaces/LoginResponse";
import SocialMediaLogin from "./SocialMediaLogin";
import { question, success } from "../../../services/sweetAlert/sweetAlert";

export interface LoginFormProps {
    value?: Partial<LoginRequest>,
    onSubmit?: (data: AuthResponse) => void,
    onRegisterClick?: () => void,
    target?: string;
}
export default function LoginForm({ value, onSubmit = () => { }, onRegisterClick, target }: LoginFormProps) {
    const [form, setForm] = useState<Partial<LoginRequest>>(value || {});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setForm(value || {}), [value]);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const res = await login(form as LoginRequest);
            onSubmit(res.data);
        } catch (err: any) {
            enqueueSnackbar(err.response.data.message || "Error inesperado", { variant: "error" });
        }
    }

    const generateTextField = (label: string, attr: keyof LoginRequest, icon: FontAwesomeIconProps["icon"], textFieldProps: TextFieldProps = {}) => (
        <Grid item xs={12}>
            <TextField
                {...textFieldProps}
                label={label} variant="outlined" fullWidth
                value={form[attr] || ""}
                onChange={e => setForm({ ...form, [attr]: e.target.value })}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FontAwesomeIcon icon={icon} />
                        </InputAdornment>
                    ),
                }}
                required
                inputProps={{ ...textFieldProps.inputProps }}
            />
        </Grid>
    );

    const showResetPasswordQuestion = async () => {
        const res = await question("¿Cual es el email del que olvidaste la contraseña?", {
            target: (target ? `#${target} .MuiDialog-container` : undefined),
            input: 'email',
            inputLabel: 'Email de la cuenta',
            inputValue: form.email,
            inputPlaceholder: "Email"
        });

        if (res.value) {
            try {
                await sendResetPasswordEmail(res.value);
                success("Email para restablecer contraseña enviado", undefined, { html: `Te hemos enviado un enlace para resetear la contraseña a <b>${res.value}</b>. En caso de no encontrarlo, comprueba tu bandeja de correo no desea / spam.` })
            } catch (error: any) {
                enqueueSnackbar("No existe ningún usuario con dicho email", { variant: "error" });
            }
        }
    }

    return (
        <Fragment>

            {/* Login Form */}
            <Grid container spacing={3} component="form" mb={2} onSubmit={handleSubmit} >
                <Grid item xs={12}>
                    <Typography variant="h4" color="text.secondary">Iniciar sesión</Typography>
                </Grid>

                <Grid item xs={12}>
                    {generateTextField("Email", "email", faEnvelope, { type: "email" })}
                </Grid>
                <Grid item xs={12}>
                    {generateTextField("Contraseña", "password", faKey, {
                        type: "password", inputProps: { minLength: 8 },
                        helperText: (
                            <Link onClick={showResetPasswordQuestion} variant="body2" sx={{ float: "right", textDecoration: "none", cursor: "pointer" }}>
                                ¿Olvidaste la contraseña?
                            </Link>
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth type="submit">Iniciar sesión</Button>
                </Grid>
            </Grid>

            {/* <SocialMediaLogin /> */}

            {/* Register info */}
            <Grid container spacing={3} mt={0}>
                <Grid item xs={12}>
                    <Divider variant="middle" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" display="inline-block" sx={{ mr: 1 }}>¿Aún no tienes cuenta?</Typography>
                    <Link variant="body2" underline="none" sx={{ cursor: "pointer" }} onClick={onRegisterClick}>
                        Regístrate aquí
                    </Link>
                </Grid>
            </Grid>

        </Fragment>
    );
}