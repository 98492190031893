import { useEffect, useState } from "react";
import Loading from "./Loading";
import axios from "axios";

export default function axiosLoading() {
    const [requests, setRequests] = useState(0);

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(function (config) {
            setRequests(value => value + 1);
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        const responseInterceptor = axios.interceptors.response.use(function (response) {
            setRequests(value => (value > 0 ? value - 1 : 0));
            return response;
        }, function (error) {
            setRequests(value => (value > 0 ? value - 1 : 0));
            return Promise.reject(error);
        });

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        }
    })

    return (
        <Loading open={requests > 0} />
    )
}