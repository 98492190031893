import { Backdrop, Box, CircularProgress, LinearProgress } from "@mui/material";
import logo from "./../../styles/icon.png";

export default function Loading(props: { open: boolean }) {
    return (
        <Backdrop open={props.open}
            sx={{
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                color: 'grey.900',
                zIndex: (theme: any) => theme.zIndex.modal + 2,
                flexDirection: 'column',
            }}
        >
            <div style={{ background: `url(${logo}) center center / contain no-repeat`, backgroundSize: "110px" }}>
                <CircularProgress color="inherit" size="50vw" thickness={0.3}
                    sx={{ maxWidth: "300px", maxHeight: "300px" }}
                />
            </div>
            <Box sx={{ width: '50%', maxWidth: '350px', mt: 2, mb: 15, color: 'grey.900' }}>
                <LinearProgress color="inherit" />
            </Box>
        </Backdrop>
    );
}